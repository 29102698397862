.inscribe-class-details-container {
   .ant-layout-content {
      text-align: -moz-left;
   }

   .title {
      display: inline-flex;
      align-items: center;

      .anticon {
         font-size: 20px;
         margin: 15px 12px 0 0;
      }
   }

   .ant-page-header {
      padding-left: 0;
   }

   .ant-page-header-heading-title {
      color: #6e1850 !important;
      font-size: 24px;
      letter-spacing: 0.24px;
      margin: 25px 0 10px;
   }

   .site-layout-background {
      width: 95%;
      margin: 20px auto;
      min-height: 280px;
   }

   .ant-typography {
      margin: 0 0 32px;
      font-size: 17px;
      color: #6e1850;
      letter-spacing: 0.17px;
   }

   .ant-collapse {
      margin-top: 32px;
   }

   .ant-card-head {
      background-color: #f5ebeb;
      font-size: 20px;
      color: #98637e !important;
      min-height: 62px;
      padding: 19px 0 19px 67px !important;
   }

   .ant-card-head-title {
      padding: 0;
   }

   // .ant-collapse-arrow {
   //    display: none !important;
   // }

   .ant-card-body {
      background-color: #fafafa;
   }

   .class-information-table {
      width: 495px;
      margin-left: 51px;

      .ant-table-thead
         > tr
         > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
         width: 0;
      }

      .ant-table-cell {
         background-color: #fafafa;
         border: 0;
      }

      .ant-table-tbody {
         .ant-table-cell {
            color: #8e8e8e;
         }
      }
   }

   h3 {
      font-size: 20px;
      color: #6e1850;
      margin-top: 32px;
   }

   .form-and-table-container {
      width: 80%;
      max-width: 1359px;
      margin: auto;
   }

   .ant-form {
      // width: 95%;
      margin: 61px auto 0;

      input {
         height: 45px;
      }

      label {
         font-size: 20px;
         font-weight: bold;
      }

      .search-person-name {
         width: 38%;
         margin-right: 1%;
      }

      .search-person-cpf {
         width: 31%;
         margin-right: 1%;
      }

      .search-button-form-item {
         width: 13%;
         margin-right: 1%;
      }

      .new-button-form-item {
         width: 15%;
      }

      .search-person-search-button {
         border-radius: 6px;
         background-color: #1db14b;
         border-color: #1db14b !important;
         color: #fafafa;
         width: 100%;
         max-width: 166px;
         min-width: 96px;
         height: 48px;
         margin-right: 1%;
         margin-top: 36px;
      }

      .search-person-new-button {
         border-radius: 6px;
         background-color: #1db14b;
         border-color: #1db14b !important;
         color: #fafafa;
         width: 100%;
         max-width: 210px;
         min-width: 130px;
         height: 48px;
         margin-top: 36px;
      }

      .ant-btn:hover,
      .ant-btn:focus {
         background-color: #fafafa !important;
         border-color: #1db14b !important;
         color: #1db14b !important;
      }
   }

   .form-error-message {
      font-weight: normal;
      color: red;
      font-size: 16px;
   }

   h4 {
      margin-top: 61px;
      font-size: 20px;
      color: #262626;
   }

   .class-search-table {
      .ant-table-thead {
         .ant-table-cell {
            background-color: #c4a7b4;
         }
      }

      .ant-table-body {
         .ant-table-cell {
            background-color: #fafafa;
         }
      }

      .inscribe-button {
         border-radius: 6px;
         border-color: #1db14b;
         color: #1db14b;
         background-color: #fafafa;
      }

      .ant-btn:hover,
      .ant-btn:focus {
         background-color: #1db14b !important;
         color: #fafafa !important;
      }
   }

   @media screen and (max-width: 720px) {
      #inscribe-class-details-content {
         margin: auto !important;
      }

      .title {
         margin: 60px auto 0px;
      }

      .ant-card-head {
         padding: 20px !important;
      }

      .ant-card-head-title {
         overflow: auto;
         white-space: normal;
         text-overflow: inherit;
      }

      .ant-form {
         .search-person-name,
         .search-person-cpf {
            width: 100%;
         }

         .search-person-search-button,
         .search-person-new-button {
            width: 130px !important;
            min-width: 0px !important;
            margin-top: inherit;
         }
      }

      .class-information-table {
         margin: auto;
      }
   }

   @media screen and (max-width: 1000px) {
      .ant-row {
         width: 100% !important;
      }
   }
}

.are-you-sure-modal {
   width: 717px !important;
   height: 339px;

   p {
      font-size: 25px;
      text-align: center;
      margin: 89px 92px 54px;
   }

   button:nth-child(1) {
      background-color: #1db14b;
      border-color: #1db14b !important;
      width: 147px;
      height: 48px;
      color: #fafafa;
      border-radius: 6px;
      font-size: 20px;
      margin-right: 50px;
   }

   button:nth-child(2) {
      border-color: #1db14b;
      width: 147px;
      height: 48px;
      color: #1db14b;
      border-radius: 6px;
      font-size: 20px;
      margin-bottom: 88px;
   }

   @media screen and (max-width: 720px) {
      p {
         margin: auto !important;
      }

      .ant-row {
         margin-top: 20px;
      }

      button:nth-child(1) {
         width: 40%;
         margin: auto;
         margin-right: 10%;
         margin-left: 5%;
      }

      button:nth-child(2) {
         width: 40%;
         margin: auto;
         margin-right: 5%;
      }
   }
}

.document-pending-modal {
   width: 800px !important;
   height: 430px;

   h3 {
      text-align: center;
      color: #e33323;
      font-size: 25px;
      font-weight: bold;
      margin-top: 80px;
   }

   .highlighted-text {
      font-size: 25px;
      color: #6e1850;
      font-weight: bold;
   }

   p {
      font-size: 25px;
      text-align: center;
      margin: 16px 78px 45px;
   }

   a {
      color: #1890ff;
   }

   button:nth-child(1) {
      background-color: #1db14b;
      width: 206px;
      height: 48px;
      color: #fafafa;
      border-radius: 6px;
      font-size: 20px;
      margin-right: 50px;
   }

   button:nth-child(2) {
      border-color: #1db14b;
      width: 206px;
      height: 48px;
      color: #1db14b;
      border-radius: 6px;
      font-size: 20px;
      margin-bottom: 88px;
   }

   .warning-button {
      background-color: #1db14b;
      width: 206px;
      height: 48px;
      color: #fafafa;
      border-radius: 6px;
      font-size: 20px;
      margin-right: 50px;
   }

   @media screen and (max-width: 720px) {
      p {
         margin: auto;
      }

      .ant-row {
         width: 80% !important;
         margin: auto;
         display: flex !important;
      }

      button:nth-child(1) {
         width: 100%;
         margin: 20px auto 20px;
      }

      button:nth-child(2) {
         width: 100%;
         margin: auto;
      }
   }
}
