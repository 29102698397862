.form-container-programAdhesionTerm {
   justify-content: flex-end;
   flex-wrap: nowrap !important;
   float: right;
   align-items: baseline;
}

.programs-list-programAdhesionTerm {
   width: 100%;
   margin-bottom: 50px;
}

.ant-list-bordered {
   border: unset !important;
}

.list-programAdhesionTerm {
   width: 100%;
   text-align: initial;
   background: #f5ebeb;

   li {
      color: #98637e;
      font-size: 16px;

      .anticon-minus,
      .anticon-plus {
         float: right;
         font-size: 24px;
         cursor: pointer;
      }
   }

   .ant-pagination-item-link {
      color: #6e1850;
   }

   .ant-pagination-next {
      :hover {
         border-color: #6e1850 !important;

         svg {
            fill: #6e1850 !important;
         }
      }
   }

   .ant-pagination-item {
      a {
         color: #c4a7b4;
      }
   }

   .ant-pagination-item-active {
      a {
         color: #98637e;
      }
      border-color: #6e1850;
   }

   .ant-table-pagination.ant-pagination {
      margin: 16px 10px;
   }
}

.form-programAdhesionTerm {
   .products-list-programAdhesionTerm {
      text-align: left;

      .products-list-header {
         display: flex;
         justify-content: space-between;
         align-items: center;

         div {
            display: flex;
         }

         .ant-btn {
            background-color: unset !important;
            border: 1px solid #6e1850;
            border-radius: 5px;
            color: #6e1850;
            font-size: 12px;
            margin: 0 5px;
         }

         .cancel-tag {
            border: 1px solid #e33323;
            color: #e33323;
            display: flex;
            align-items: center;
         }

         .anticon-close {
            color: #e33323;
            align-self: center;
         }
      }

      .ant-collapse-header {
         background: #f5ebeb;
         font-size: 16px;
         color: #98637e !important;
      }

      .ant-collapse-content-box {
         background: #fafafa;
         font-size: 16px;
         color: #262626;
         display: flex;
         flex-direction: column;
         padding: 10px 25px !important;

         .adhesionProductButton {
            color: #98637e;
            text-align: -webkit-center;
            span {
               font-size: 20px;
               margin-right: 5px;
            }

            div {
               .remover {
                  display: none;
               }

               .clicado .aderir {
                  display: none !important;

                  .remover {
                     display: flex !important;
                  }
               }
            }
         }

         .products {
            display: flex;
            justify-content: space-between;
         }

         .products-dependente p {
            color: #dbdbdb !important;
            cursor: not-allowed;
         }

         .products-avulso {
            color: #262626;
         }
      }

      .ant-collapse {
         width: 100%;
      }

      .spin-panel {
         text-align: center;
      }
   }

   .row-btn {
      justify-content: center;
      margin: 25px 0;

      button {
         width: auto !important;
      }
   }
}

.table-programAdhesionTerm {
   .program-adhesion-ok-status {
      color: #20a547;
      font-weight: bold;
   }

   .program-adhesion-ok-pending {
      color: #ffc30c;
      font-weight: bold;
   }

   .ant-col {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
         font-size: 14px;
         cursor: pointer;
      }

      .delete-tag {
         background-color: unset !important;
         border: none;
         color: #6e1850;
      }
   }

   .ant-table-wrapper {
      width: 100%;
   }

   .ant-table-thead {
      line-height: normal;
      th {
         background: #f5ebeb;
         color: #98637e;
         font-size: 18px;
         text-align: center;
         padding: 10px 20px;
         white-space: pre;
      }
   }

   .ant-table-tbody {
      background: #fafafa;
      color: #8e8e8e;
      font-size: 16px;
      text-align: center;
      vertical-align: -webkit-baseline-middle;

      p {
         margin: 10px;
      }

      .ant-tag {
         background-color: unset !important;
         border: 1px solid #6e1850;
         border-radius: 5px;
         color: #6e1850;
      }

      .edit-term-tag {
         border: 1px solid #1db14b;
         color: #1db14b;
         padding: 5px;
         font-size: 15px;
         cursor: pointer;
      }
   }

   .ant-pagination-item-link {
      color: #6e1850;
   }

   .ant-pagination-next {
      :hover {
         border-color: #6e1850 !important;

         svg {
            fill: #6e1850 !important;
         }
      }
   }

   .ant-pagination-item {
      background: unset !important;

      a {
         color: #c4a7b4;
      }
   }

   .ant-pagination-item-active {
      a {
         color: #98637e;
      }
      border-color: #6e1850;
   }

   .ant-table-pagination.ant-pagination {
      margin: 16px 10px;
   }
}

.modal-button {
   width: 60%;
   height: 40px !important;
   background-color: #1db14b !important;
   color: white !important;
   border-radius: 0.5rem !important;
}

.confirm-delete-modal {
   width: 535px !important;
   height: 319px;

   .ant-btn:hover,
   .ant-btn:focus {
      border-color: #fafafa !important;
   }

   .ant-modal-body {
      padding: 75px;
      font-size: 20px;
      text-align: center;
   }

   .ant-row {
      margin-top: 5px;
      margin-bottom: 23px;
   }

   span {
      font-size: 20px;
   }

   .ant-checkbox-inner {
      border-radius: 100%;
   }
}

.delete-reason-modal {
   width: 717px !important;

   .ant-modal-body > p {
      font-size: 20px;
      text-align: center;
   }

   .ant-modal-body > p > span {
      color: #e33323;
   }

   .ant-checkbox-wrapper span {
      font-size: 16px;
   }

   .ant-checkbox-inner {
      border-radius: 100%;
   }

   .ant-row {
      margin-bottom: 10px;
   }

   textarea {
      width: 90%;
   }

   .modal-button {
      width: 246px;
      height: 48px;
      margin-top: 10px;
      font-size: 18px;
   }

   .ant-btn:hover,
   .ant-btn:focus {
      border-color: #fafafa !important;
   }
}
@media screen and (max-width: 530px) {
   .table-programAdhesionTerm {
      .ant-col {
         display: block;
         text-align: left;

         .delete-tag {
            border: 1px solid;
            border-radius: 5px;
            padding: 7px;
            margin-bottom: 10px;
         }

         span {
            font-size: 16px;
         }
      }
   }
}

@media screen and (max-width: 680px) {
   .form-programAdhesionTerm .products-list-programAdhesionTerm {
      padding-left: 0;

      .products-list-header {
         div {
            display: block !important;
            float: right;
         }

         p {
            text-align: center;
            font-size: 18px;
            width: -webkit-fill-available;
         }

         .tag-button {
            float: right;
            margin: 5px;
            font-size: 16px;
            text-align: center;
            padding: 4px;
            min-width: 160px;
            white-space: break-spaces;
            width: min-content;
            height: min-content;
         }
      }

      .ant-collapse
         > .ant-collapse-item.ant-collapse-no-arrow
         > .ant-collapse-header {
         padding: 12px 5px;
      }

      .ant-collapse-content-box .adhesionProductButton {
         display: flex;
         align-items: center;
         width: min-content;
         min-width: 105px;
         line-height: normal;
      }
   }

   .table-programAdhesionTerm {
      .ant-table-tbody {
         font-size: 18px;

         tr > td,
         .ant-table tfoot > tr > th,
         .ant-table tfoot > tr > td {
            position: relative;
            padding: 5px;
            overflow-wrap: break-word;
            vertical-align: middle;
         }
      }
   }
}
