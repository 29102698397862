.programs-search-container {
   padding-bottom: 20px;
   background: #ffffff;

   .ant-btn-primary {
      width: 158px;
      height: 45px;
      background: #1db14b;
      border-radius: 6px !important;
      border-color: #1db14b;
      opacity: 1;
      margin: 0 10px;
      color: white;
      font-size: 16px;
   }

   .ant-btn-primary:hover {
      background-color: #1db14b;
      border-color: #fafafa !important;
      color: #fafafa;
   }

   .ant-btn-primary:focus {
      background-color: #1db14b;
      border-color: #fafafa !important;
      color: #fafafa;
   }

   .default-field {
      height: 45px !important;
      opacity: 1;
      background: #fafafa;
      border: 1px solid #dbdbdb;
   }

   .site-layout {
      background: #ffffff 0% 0% no-repeat padding-box;
   }

   .ant-page-header-heading-title {
      color: #6e1850;
      font-size: 24px;
      letter-spacing: 0.24px;
      margin: 25px 0 10px;
   }

   .site-layout-background {
      margin: 24px 35px;
      min-height: 280px;
      text-align: -webkit-center;
   }

   .form-container-programs {
      justify-content: center;
   }

   h4 {
      float: left;
      font-weight: normal !important;
      margin-bottom: 30px !important;
   }

   .default-table {
      th {
         background: #c4a7b4;
         font-size: 16px;
         line-height: 15px;
      }
      td {
         background: #fafafa;
         font-size: 16px;
         color: #8e8e8e;

         span {
            float: right;
         }
         svg {
            font-size: 20px;

            .a {
               fill: #6e1850;
            }
         }
      }

      .ant-pagination-item-link {
         color: #6e1850;
      }

      .ant-pagination-next {
         :hover {
            border-color: #6e1850 !important;

            svg {
               fill: #6e1850 !important;
            }
         }
      }

      .ant-pagination-item {
         a {
            color: #c4a7b4;
         }
      }

      .ant-pagination-item-active {
         a {
            color: #98637e;
         }
         border-color: #6e1850;
      }

      .ant-table-pagination.ant-pagination {
         margin: 16px 2%;
      }

      .ant-table {
         width: 96%;
      }
   }

   .form-programs {
      width: 70%;
   }

   .anticon-edit {
      float: right;
      svg {
         font-size: 25px;
      }
   }

   .title {
      color: #262626;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 6px;
      text-align: left;
   }
   .subtitle-prog {
      margin: -10px 24px 30px;
      color: #262626;
      font-size: 16px;
      z-index: 1;
   }

   .col {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      max-width: 420px;
      width: 100%;
   }

   .row {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
   }

   .button-goback {
      margin-right: 16px;
      background-color: #ffffff;
      color: #1db14b;
   }
   .errorText {
      align-self: flex-end;
      color: #e33323;
   }
   .productViewFirstLevel {
      flex-direction: row;
      color: #98637e;
      flex: 1;
      font-size: 16px;
      text-align: left;
      display: flex;
   }
   .productView2 {
      flex-direction: row;
      background-color: #c4a7b4;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 10px;
      height: 24px;
      border-radius: 6px;
      font-size: 14px;
      display: flex;
   }
   .productView3 {
      display: flex;
      align-items: flex-start;
   }

   .select {
      width: 100%;
      text-align: left;
      font-size: 16px;
   }

   .adhesion-term-button {
      border-radius: 6px;
   }

   .line-field-height {
      margin-bottom: 30px !important;
   }
   @media screen and (max-width: 300px) {
      .ant-form-horizontal {
         min-width: 0 !important;
      }
   }

   @media screen and (max-width: 720px) {
      #programs-add-subtitle {
         margin: 0px 24px 30px !important;
      }

      .ant-form {
         width: 90% !important;
      }

      .site-layout-background {
         margin: 0 !important;
      }

      .form-programs {
         width: 90%;
      }

      .ant-btn-primary {
         width: 80px;
      }

      #programs-search-button,
      #programs-new-program {
         width: auto !important;
         padding: 0px 2%;
      }

      .ant-page-header-heading-title {
         margin-top: 60px !important;
      }

      .ant-page-header {
         padding: 16px 20px;
      }

      .ant-page-header-heading {
         margin: 10px 0 0 0 !important;
         white-space: break-spaces !important;
         font-size: 22px !important;
      }

      .ant-form-item {
         margin: 0px 0 10px 0 !important;
      }

      .subtitle-prog {
         margin: -30px 24px 30px !important;
      }

      .ant-radio-group,
      .ant-upload.ant-upload-select {
         float: left;
      }

      .programs-search-container {
         .site-layout-background {
            text-align: left;
         }

         .form-programs {
            width: 100%;
         }

         .form-container-programs {
            text-align: center;
         }

         .productViewFirstLevel {
            word-break: normal;
         }
      }

      .ant-col-6,
      .ant-col-8 {
         max-width: 100% !important;
      }
   }

   @media screen and (min-width: 721px) and (max-width: 900px) {
      .ant-col-6 {
         display: contents !important;
      }

      .programs-search-container {
         .form-programs {
            width: 100% !important;
         }

         .form-container-programs {
            justify-content: flex-start !important;
         }
      }
   }
}
