.inscribe-entity-product-detail-container {
   .ant-layout-content  {
      text-align: -moz-left;
   }

   .ant-page-header {
      padding: 20px 0;
   }

   .ant-page-header-heading-title {
      color: #6e1850 !important;
      font-size: 24px;
      letter-spacing: 0.24px;
      margin: 25px 0 20px;
   }

   .site-layout-background {
      width: 80%;
      margin: 20px auto;
      min-height: 280px;
   }

   .title {
      display: inline-flex;
      align-items: center;

      .anticon {
         font-size: 20px;
         margin: 10px;
      }
   }

   .breadcrumb {
      font-size: 17px;
      color: #6E1850;
      letter-spacing: 0.17px;
   }

   .ant-card-head {
      background: #F5EBEB;
      padding: 0 50px;

      .ant-card-head-title {
         color: #98637E;
         font-size: 20px;
      }
   }

   .ant-card-body {
      padding: 30px 50px;
   }

   .product-name {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      h5 {
         color: #6E1850;
         font-size: 16px;
      }

      .workload-price {
         p {
            color: #6E1850;
            font-size: 12px;
            text-transform: uppercase;
         }
      }
   }

   .workload-price {
      display: flex;

      p {
         margin: 0 15px;
      }
   }

   .product-description-container {
      margin-bottom: 25px;
      flex-direction: column;

      .product-description {
         padding: 10px;
         border: 1px solid #DBDBDB;
         background-color: #EEEEEE;

         p {
            font-size: 12px;
            color: #707070;
         }
      }
   }

   .city-container {
      margin-bottom: 25px;
   }

   .ant-table-wrapper {

      .ant-table-thead {
         .ant-table-cell {
            background: #C4A7B4;
         }
      }

      .ant-table-tbody {
         .ant-table-cell {
            background: #EEEEEE;
         }
      }
   }

   .ant-select {
      width: 300px;
   }

   @media screen and (max-width: 720px) {
      .ant-page-header-heading-title {
         margin: 60px auto 30px !important;
      }

      .ant-card-body {
         padding: 5px;
      }

      .ant-select {
         width: 100%;
      }
   }
}

