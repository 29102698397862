.socprofiles-search-container {
   padding-bottom: 20px;
   background: #ffffff;

   .ant-btn-primary {
      width: 158px;
      height: 45px;
      background: #1db14b;
      border-radius: 6px !important;
      border-color: #1db14b !important;
      opacity: 1;
      margin: 0 10px;
      color: white;
      font-size: 16px;
   }

   .ant-btn-primary:hover {
      background-color: #1db14b;
      border-color: #fafafa !important;
      color: #fafafa;
   }

   .ant-btn-primary:focus {
      background-color: #1db14b;
      border-color: #fafafa !important;
      color: #fafafa;
   }
   .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px;
   }
   .ant-page-header {
      padding: 0px 24px;
      margin-top: 20px;
   }

   .site-layout {
      background: #ffffff 0% 0% no-repeat padding-box;
   }

   .ant-page-header-heading-title {
      color: #6e1850 !important;
      font-size: 24px;
      letter-spacing: 0.24px;
      margin: 25px 0 10px;
   }

   .site-layout-background {
      margin: 0 25px;
      text-align: left;
   }

   h4 {
      float: left;
      font-weight: normal !important;
      margin-bottom: 30px;
   }

   .title-questions {
      color: #262626;
      text-align: left !important;
      margin-bottom: 10px;
      font-size: 20px;
   }

   .subtitle {
      margin-bottom: 10px;
      color: #262626;
      font-size: 16px;
      z-index: 1;
      text-align-last: left;
   }

   .col {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
   }

   .row {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      margin-bottom: 30px;
   }
   .row-vertical {
      display: flex;
      align-items: flex-start;
      margin: 16px 0;
      flex-direction: column;
   }

   .button-goback {
      margin-right: 16px;
      background-color: #ffffff;
      color: #1db14b;
   }

   .target-audience-checkbox-container {
      margin-bottom: 6px;
   }

   .checkbox-label {
      font-size: 16px;

      input {
         margin-right: 7px;
         width: 16px;
         height: 16px;
      }
   }

   .input-checkbox {
      height: 20px;
      font-size: 16px;
      border: none;
      outline: none;
      border-bottom: 1px solid #000;
      width: 300px;
      margin: 0 0 0 2px;
   }

   .other-option-checkbox {
      display: flex;
      flex-direction: row;
   }

   .other-option-radio {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
   }

   input[type='number'] {
      border: none;
      border-bottom: 1px solid;
      margin: 0 3px;
      outline: none;
      width: 60px;
   }
   input[type='number']::-webkit-inner-spin-button {
      opacity: 1;
   }

   .ant-input {
      height: 20px !important;
   }
}

.socprofiles-modal {
   width: 1024px !important;
   height: 535px;

   .ant-modal-title {
      color: #6e1850;
      font-weight: 600;
      font-size: 20px;
   }

   .ant-modal-body {
      font-size: 20px;
      text-align: center;
   }

   .ant-row {
      margin-top: 5px;
      margin-bottom: 23px;
   }

   span {
      font-size: 16px;
   }

   @media screen and (max-width: 300px) {
      .socprofiles-search-container {
         .input-checkbox {
            width: 65% !important;
         }

         .ant-form-horizontal {
            min-width: 233px !important;
         }
      }
   }

   @media screen and (max-width: 720px) {
      .ant-page-header {
         padding: 16px 20px;
      }

      .ant-page-header-heading-title {
         margin: 20px 0 10px !important;
         white-space: break-spaces !important;
         font-size: 22px !important;
      }

      .ant-row {
         display: inline-block !important;
         width: 100% !important;
      }

      .ant-col-10,
      .ant-col-12,
      .ant-col-16 {
         max-width: 100% !important;
      }

      .ant-radio-wrapper {
         font-size: 16px !important;
      }

      .socprofiles-search-container {
         .input-checkbox {
            width: 75% !important;
            min-width: auto !important;
            float: left;
         }

         .ant-form-horizontal {
            min-width: 280px !important;
         }
      }

      .site-layout-background {
         margin: 0 24px 0 24px !important;
      }

      .form-entities {
         width: 100% !important;
         margin-bottom: 0 !important;

         .ant-col-6 {
            display: block !important;
            max-width: none !important;
         }
      }

      .title-questions {
         font-size: 17px !important;
         font-weight: bold;
      }

      input[name='entity_name'],
      .ant-picker,
      input[name='cnpj2'],
      input[name='corporate_name'],
      input[name='cep'],
      input[name='adress'],
      input[name='number'],
      input[name='complement'],
      input[name='neighborhood'],
      input[name='city'],
      input[name='state'],
      input[name='phone'],
      input[name='mobile_phone1'],
      input[name='mobile_phone2'],
      input[name='email'],
      input[name='site'] {
         width: auto;
         min-width: -webkit-fill-available;
      }

      .checkbox {
         float: left;
         text-align: left;
      }

      .other-option-checkbox {
         justify-content: flex-start !important;
      }
      .other-option-radio {
         display: inline-block !important;
      }

      .row-vertical {
         margin: 0 !important;
         margin-bottom: 20px !important;
      }

      .ant-btn-group {
         position: relative;
         display: inline-flex;
         float: left;

         .ant-btn {
            width: 80px;
         }
      }

      .socprofiles-search-container .subtitle {
         text-align-last: center !important;
         padding: 8px 26px;
      }
      .socprofiles-modal {
         .ant-modal-body {
            padding: 0px;
         }
      }
   }

   @media screen and (min-device-width: 721px) and (max-device-width: 1024px) {
      input[name='entity_name'],
      .ant-picker,
      input[name='cnpj'],
      input[name='corporate_name'],
      input[name='cep'],
      input[name='adress'],
      input[name='number'],
      input[name='complement'],
      input[name='neighborhood'],
      input[name='city'],
      input[name='state'],
      input[name='phone'],
      input[name='mobile_phone1'],
      input[name='mobile_phone2'],
      input[name='email'],
      input[name='site'] {
         width: auto !important;
      }

      .subtitle {
         text-align-last: center !important;
         padding: 0 26px;
      }

      .socprofiles-modal {
         .ant-modal-body {
            padding: 8px;
         }
      }
   }
}
