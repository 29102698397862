.terms-container{
   .ant-page-header-heading-title{
      color: #6E1850!important;
      font-size: 24px;
      letter-spacing: 0.24px;
      margin: 25px 0;
   }

   #last-lgpd-signed-term {
      margin-top: 20px;
   }

   @media screen and (max-width: 640px) {
      .ant-page-header-heading-title{
         margin: 50px 0 !important;
      }
   }

   .ant-col{
      margin: 0 25px;
      max-width: 300px;
   }

   .ant-upload-drag-container{
      svg{
         font-size: 30px;
      }

      .a{
         fill: #8E8E8E;
      }

      .ant-upload-text{
         font-size: 16px;
         color: #8E8E8E!important;
      }
   }

   .ant-upload.ant-upload-drag p.ant-upload-text{
      margin: 0 4px 4px;
   }
}
