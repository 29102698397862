* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

body {
  background: white;
  color: black;
}

body,
input,
textarea,
select,
button {
  font: 400 1rem 'Lato', sans-serif;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

.title {
  font-size: 18px;
}

.ant-input {
  font-size: 16px !important;
}

.multi-row {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}

.default-field-container {
  padding: 0 22px 6px 0;

  .MuiTypography-caption.required,
  &.required .MuiTypography-caption {
    &::before {
      content: '*';
      color: #0075be;
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      line-height: 1;
      margin-right: 2px;
    }
  }
  .MuiOutlinedInput-root.Mui-disabled {
    background-color: #f2f2f2;
    color: #3b3b3d;

    fieldset {
      border: none;
    }
  }
}

.default-form-field {
  width: 100%;

  .MuiOutlinedInput-root {
    width: 100%;
  }
}

.ant-radio-checked {
  border-color: #98637e !important;

  ::after {
    background-color: #98637e;
  }
}

.ant-radio-inner {
  border-color: #98637e !important;

  ::after {
    background-color: #98637e;
  }
}

.ant-input:hover {
  border-color: #9dd29c !important;
  border-right-width: 1px !important;
  box-shadow: 0px 0px 6px #9dd29c !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #9dd29c !important;
  border-right-width: 1px !important;
}

.ant-input-password:hover {
  border-color: #9dd29c !important;
  border-right-width: 1px !important;
}

.ant-input-password:focus,
.ant-input-affix-wrapper-focused {
  border-color: #9dd29c !important;
  border-right-width: 1px !important;
}

.ant-select-selector {
  font-size: 16px !important;
}

.ant-select-selector:hover {
  border-color: #9dd29c !important;
  border-right-width: 1px !important;
}

.ant-select-selector:focus {
  border-color: #9dd29c !important;
  border-right-width: 1px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #98637e;
  border-color: #98637e;
}

.productViewFirstLevel {
  font-size: 16px !important;
  font-weight: 600;
  align-items: center;
  border: 1px solid;
  border-radius: 6px;
  display: flex;
  padding: 2px 8px;
  margin: 0px 10px 10px 0;

  .productView3 {
    align-items: center;
  }
}

#root {
  height: inherit;
}

#root .spin {
  display: flex;
  justify-content: center;
  height: inherit;
  align-items: center;
}

.table {
  max-width: 75%;
  &-full {
    width: 100%;
  }
}

.ant-layout-content {
  text-align: -moz-center;
}

@media screen and (max-width: 600px) {
  .ant-form-horizontal {
    line-height: normal !important;
    min-width: 260px !important;
    // display: inline-table;
  }

  .multi-row {
    align-items: normal !important;
    flex-direction: column !important;
  }
}

.text-left {
  text-align: left;
}

.ant-message {
  font-size: 18px !important;

  .success {
    .ant-message-notice-content {
      background-color: #e8f6ce;
      color: #707070;
    }
  }
  .warning {
    .ant-message-notice-content {
      background-color: #fff2c8;
      color: #707070;
    }
  }
  .error {
    .ant-message-notice-content {
      background-color: #f8dfdd;
      color: #707070;
    }
  }
}
