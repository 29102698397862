.inscribe-entity-collaborator-participant-container {
   .ant-layout-content  {
      text-align: -moz-left;
   }

   .ant-page-header-heading-title {
      color: #6e1850 !important;
      font-size: 24px;
      letter-spacing: 0.24px;
      margin: 25px 0 10px;
   }

   .site-layout-background {
      width: 80%;
      margin: 20px auto;
      min-height: 280px;
   }

   .ant-typography {
      margin: 0 25px;
      font-size: 17px;
      color: #6E1850;
      letter-spacing: 0.17px;
      text-align: -moz-left;
   }

   .ant-table-thead {
      font-size: 20px;

      .ant-table-cell {
         background-color: #f5ebeb;
         color: #98637e;
         padding-left: 42px;
      }
   }

   .title {
      display: inline-flex;
      align-items: center;

      .anticon {
         font-size: 20px;
         margin: 10px;
      }
   }

   .ant-table-tbody {
      font-size: 18px;

      .ant-table-cell {
         background-color: #fafafa;
         color: #8e8e8e;
         padding-left: 42px;
      }

      .anticon-plus,
      .anticon-minus {
         color: #98637e;
         font-size: 20px;
      }
   }

   .product-panel {
      background-color: #fff;

      .ant-collapse-header {
         height: 57px;
         display: grid;
         grid-template-columns: 7fr 1fr;
         grid-template-rows: 1fr;
         gap: 0px 0px;
         grid-template-areas: 'Header Button';
      }

      .ant-collapse-header-text {
         grid-area: Header;
      }

      .ant-collapse-extra {
         grid-area: Button;
      }

      h5 {
         font-size: 16px;
         color: #6e1850;
         margin-left: 34px;
         font-weight: bold;
         // width: 80%;
         // height: 100%;
      }

      button {
         height: 37px;
         width: 158px;
         background-color: #fff;
         color: #6e1850;
         border: 1px solid #6e1850;
         border-radius: 0.3rem;
         margin-right: 18px;
      }

      .ant-list-item {
         width: 87%;
         margin: auto;
      }
   }

   .warning-text {
      margin-left: 25px;
   }

   @media screen and (min-width: 721px) and (max-width: 1400px) {
      .ant-table-pagination.ant-pagination {
         margin: 16px -4%;
      }
   }
}
