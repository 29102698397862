.naturalperson-search-container {
   .ant-layout-content {
      text-align: -moz-left;
   }

   .ant-btn-primary {
      width: 158px;
      height: 45px;
      background: #1db14b;
      border-radius: 6px !important;
      border-color: #1db14b !important;
      opacity: 1;
      margin: 0 10px;
      color: white;
      font-size: 16px;
   }

   .ant-btn-primary:hover {
      background-color: #1db14b;
      border-color: #fafafa !important;
      color: #fafafa;
   }

   .ant-btn-primary:focus {
      background-color: #1db14b;
      border-color: #fafafa !important;
      color: #fafafa;
   }
   .ant-checkbox-wrapper {
      margin-left: 5px;
   }
   .ant-page-header {
      padding: 0px 24px;
      margin-top: 20px;
   }
   .default-field {
      height: 42px;
      background: #fafafa;
      border: 1px solid #dbdbdb;
      width: -webkit-fill-available;
      max-width: 800px;
      margin: 0 15px 0 0;
   }

   .site-layout {
      background: #ffffff 0% 0% no-repeat padding-box;
   }

   .ant-page-header-heading-title {
      color: #6e1850 !important;
      font-size: 24px;
      letter-spacing: 0.24px;
      margin: 25px 0 10px;
   }

   .site-layout-background {
      margin: 24px 35px;
      min-height: 280px;
      text-align: -webkit-center;
      text-align: -moz-center;
   }

   .form-container-np {
      justify-content: center;
      align-items: center;
      margin: 10px 0 0 0;
   }

   h4 {
      float: left;
      font-weight: normal !important;
      margin-bottom: 30px;
   }

   .form-error-message {
      font-weight: normal;
      color: red;
      font-size: 16px;
   }

   .search-result-table {
      max-width: 90%;
   }

   .button-group {
      margin-top: 22px;
   }

   .default-table {
      th {
         background: #c4a7b4;
         font-size: 16px;
         font-weight: 600;
         line-height: 10px;
      }
      td {
         background: #fafafa;
         font-size: 16px;
         color: #8e8e8e;

         span {
            float: right;
         }
         svg {
            font-size: 20px;

            .a {
               fill: #6e1850;
            }
         }
      }

      .ant-pagination-item-link {
         color: #6e1850;
      }

      .ant-pagination-next {
         :hover {
            border-color: #6e1850 !important;

            svg {
               fill: #6e1850 !important;
            }
         }
      }

      .ant-pagination-item {
         a {
            color: #c4a7b4;
         }
      }

      .ant-pagination-item-active {
         a {
            color: #98637e;
         }
         border-color: #6e1850;
      }

      .ant-table-pagination.ant-pagination {
         margin: 16px 7%;
      }

      .ant-table {
         width: 86%;
      }
   }

   .form-naturalperson {
      width: 90%;
      margin-bottom: 50px;

      .ant-col-6 .ant-col-8 {
         margin-right: auto;
      }
   }

   .anticon-edit {
      float: right;
      svg {
         font-size: 25px;
      }
   }
   .title {
      font-size: 20px;
      color: #262626;
      margin: 10px 0 0 0;
      text-align: left;
   }

   .title-cpf {
      font-size: 17px;
      margin: 0 0 0 0;
   }

   .title-search {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
   }

   .title-term {
      font-size: 15px;
   }

   .subtitle {
      margin: -10px 24px 30px;
      color: #262626;
      font-size: 16px;
      z-index: 1;
   }

   .col {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
   }

   .col-left {
      width: 19.8%;
   }

   .row-vertical {
      display: flex;
      align-items: flex-start;
      margin-top: 16px;
      flex-direction: column;
   }

   .button-goback {
      margin-right: 16px;
      background-color: #ffffff;
      color: #1db14b;
   }

   .np-div {
      margin: 10px 0px;
      justify-content: space-between;
      font-size: 16px;
   }

   .contact-div {
      margin: 10px 0px;
      font-size: 16px;

      .ant-input-disabled {
         background: #f5f5f5;
      }
   }

   .np-button {
      border: 1px solid #98637e;
      border-radius: 8px;
      color: #98637e;
      font-weight: 600;
      margin: 0px 12px 0 0;
      align-self: center;
   }

   .errorText {
      align-self: flex-end;
      color: #e33323;
   }

   .field-set {
      border: 2px solid #c4a7b4;
      display: 'flex';
      justify-content: flex-start;
      flex-direction: column;
      padding: 20px;
      margin: 20px 10px 40px 10px;
   }

   .field-set-title-div {
      display: flex;
      text-align: left;
      margin-top: -40px;
   }

   .field-set-title {
      background-color: white;
      padding: 0 12px;
      font-size: 24px;
      color: #262626;
      font-weight: bold;
   }

   .field-set-children {
      padding: 0 20px;
   }

   .default-button,
   .button-goback {
      margin: 0 0 20px 20px !important;
   }

   .identify-field {
      width: 100%;
      max-width: 350px;
   }

   .identify-field > .ant-select-lg {
      width: 100%;
   }

   #add-form-uf-col {
      .ant-select-selector,
      input[type='text'] {
         width: 100%;
         width: 100px;
      }
   }

   .default-field-container {
      padding: 0 22px 0px 0;
   }
   #add-form-extra-small-col {
      .ant-select-selector,
      input[type='text'] {
         width: 100%;
         width: 180px;
      }
   }
   #add-form-xs-col {
      .ant-select-selector,
      input[type='text'] {
         width: 100%;
         width: 290px;
      }
   }

   #add-form-sm-col {
      .ant-select-selector,
      input[type='text'] {
         display: flex;
         width: 360px;
      }
   }

   #add-form-md-col {
      .ant-select-selector,
      input[type='text'] {
         width: 100%;
         width: 459px;
      }
   }

   #add-form-large-col {
      .ant-select-selector,
      input[type='text'] {
         height: 40px;
         width: 100%;
         width: 752px;
      }

      .ant-select-show-search {
         margin-right: 21px;
      }
   }

   #add-form-extra-large-col {
      .ant-select-selector,
      input[type='text'] {
         height: 40px;
         width: 100%;
         width: 970px;
      }

      .ant-select-show-search {
         margin-right: 21px;
      }
   }

   .ant-upload-disabled {
      button {
         color: darkgrey;
         border-color: darkgray;
         cursor: not-allowed;
      }
   }

   #col-upload {
      align-self: center;
   }

   .ant-form-item {
      input {
         background-color: white;
      }
   }

   .ant-spin-spinning {
      display: block !important;
   }

   @media screen and (max-width: 768px) {
      .identify-field {
         width: 100%;
         max-width: none;
      }

      .identify-field > .ant-select-lg {
         width: 100%;
      }
   }

   @media screen and (min-device-width: 768px) and (max-device-width: 1300px) {
      .identify-field {
         width: 80%;
      }

      .identify-field > .ant-select-lg {
         width: 80%;
      }
   }

   @media screen and (max-width: 720px) {
      .ant-page-header {
         padding: 16px 20px;
      }

      .ant-page-header-heading-title {
         margin: 60px 0 10px !important;
         white-space: break-spaces !important;
         font-size: 22px !important;
      }

      .subtitle {
         margin: 024px 30px;
         margin-bottom: 0 !important;
         word-break: break-word;
      }

      .search-result-table {
         max-width: 100%;
      }

      .site-layout-background {
         margin: 0 24px 0 24px !important;
      }

      .form-naturalperson {
         width: 100% !important;
         margin-bottom: 0 !important;
      }

      .col {
         margin: 0 !important;
      }

      .form-container-np,
      .ant-form-item {
         margin: 0 !important;
         margin-bottom: 10px !important;
      }

      .ant-btn-primary {
         width: auto;
         padding: 0 2%;
      }

      .button-goback,
      .default-button {
         width: 80px;
      }

      .ant-col-8 {
         max-width: none !important;
      }

      .title-search,
      .title {
         font-size: 19px !important;
      }

      .np-button {
         margin: 12px 12px 2px 0;
         font-size: 15px;
      }

      .default-table {
         .ant-table {
            width: 100%;
            display: block;
            overflow-x: scroll;
         }

         th {
            line-height: 1.3;
            padding: 5px 8px;
         }

         td {
            line-height: 1.3;
         }
      }

      .field-set {
         border: none !important;
         margin: 0 !important;
         padding: 0 10px 0px 10px !important;
      }

      .field-set-children {
         padding: 0 12px !important;
         margin-top: 0 !important;
         margin-bottom: 10px;
      }

      .field-set-title-div {
         display: inline-block !important;
         margin-top: 0 !important;
      }

      .title,
      .title-term,
      .title-cpf {
         margin: 16px 0 0 0 !important;
         word-break: break-word;
         font-size: 18px !important;
      }

      .np-div {
         margin: 0px 0 !important;
         padding: 0 !important;
      }

      .col-left {
         width: 100% !important;
      }

      .ant-table-tbody > tr > td,
      .ant-table tfoot > tr > th,
      .ant-table tfoot > tr > td {
         padding: 15px !important;
         width: 135px;
         white-space: nowrap !important;
         text-overflow: ellipsis !important;
      }

      button[type='submit'] {
         margin-bottom: 0 !important;
      }

      .ant-select,
      .ant-select-selector,
      .default-field,
      input[type='text'] {
         display: flex !important;
         // margin: auto !important;
         width: 100% !important;
         min-width: -webkit-fill-available !important;
      }

      .row-vertical {
         margin-bottom: 20px !important;
      }

      .ant-btn-group {
         position: relative;
         display: inline-flex;
         float: left;

         .ant-btn-primary {
            height: 42px;
            margin: 10px 10px;
         }
      }

      .default-table .ant-table-pagination.ant-pagination {
         margin: 20px auto;
      }
   }

   @media screen and (min-device-width: 721px) and (max-device-width: 1024px) {
      .naturalperson-search-container {
         .site-layout-background {
            margin: 0 !important;
         }
         .form-naturalperson {
            margin-bottom: 30px;
         }
         .field-set-children {
            padding: 0px;
         }
         .subtitle {
            text-align-last: start !important;
            padding: 0px;
         }
         .np-div {
            justify-content: flex-start;
         }
         .np-button {
            margin: 14px 6px 0 0;
         }
      }

      .title-search,
      .title {
         font-size: 14px !important;
         .title {
            font-size: 14px !important;
            margin-top: 0px !important;
         }
      }

      .table {
         max-width: 90% !important;
      }

      .naturalperson-search-container .default-table th,
      .naturalperson-search-container .default-table td {
         line-height: 1.3;
         padding: 5px 8px;
      }

      #add-form-large-col {
         .ant-select-selector,
         input[type='text'] {
            width: auto !important;
            min-width: 600px !important;
            height: 42px !important;
         }
      }

      .ant-select-selector,
      .default-field,
      #add-form-md-col #add-form-sm-col #add-form-xs-col {
         .ant-select-selector,
         input[type='text'] {
            width: auto !important;
            min-width: -webkit-fill-available !important;
            height: 42px !important;
         }
      }
   }
}
