.dashboard-container{
   h2{
      color: #6E1850!important;
      width: 100%;
      margin: 10px 40px;
      font-weight: bolder!important;
   }

   .ant-col{
      margin: 24px;
      h4{
         font-weight: normal;
         font-size: 18px;
         color: white;
      }
   }

   .cards-home {
      justify-content: center;
   }

   .logos{
      text-align: center;
      img{
         margin: 30px 20px;
      }
   }

   .ant-card {
      width: 500px!important;
      margin: 10px
   }

   .purple-card{
      background: #6E1850;

      ::after{
         position: absolute;
         right: -34px;
         width: 80px;
         height: 50px;
         transform: rotate(130deg);
         background-color: #fff;
      }
   }

   .green-card{
      border: 2px solid #76c37a;

      .ant-typography{
         font-size: 24px;
         color: #1DB14B;
      }

      ::after{
         position: absolute;
         height: 50px;
         transform: rotate(136deg);
         background-color: #fff;
         border-bottom: 2px solid #76c37a;
         right: -26px;
         width: 55px;
      }
   }

   .banner{
      height: 130px;
      background: url('../../assets/images/banner.png') no-repeat right;
      align-content: center;
   }
}

@media screen and (max-width: 720px) {
   h2{
      font-size: 24px !important;
   }
   .dashboard-container{
      .banner {
         height: 85px;
         padding: 0.5%;
      }
      h2 {
         margin: 3% 0;
         text-align: center;
      }
      .ant-card {
         width: auto !important;
      }
   }
}
