.entrepreneurs-search-container {
   .ant-btn-primary {
      width: 158px;
      height: 45px;
      background: #1db14b;
      border-radius: 6px !important;
      border-color: #1db14b !important;
      opacity: 1;
      margin: 0 10px;
      color: white;
      font-size: 16px;
   }

   .ant-btn-primary:hover {
      background-color: #1db14b;
      border-color: #fafafa !important;
      color: #fafafa;
   }

   .ant-btn-primary:focus {
      background-color: #1db14b;
      border-color: #fafafa !important;
      color: #fafafa;
   }
   .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px;
   }
   .ant-page-header {
      padding: 0px 24px;
      margin-top: 20px;
   }

   .site-layout {
      background: #ffffff 0% 0% no-repeat padding-box;
   }

   .ant-page-header-heading-title {
      color: #6e1850 !important;
      font-size: 24px;
      letter-spacing: 0.24px;
      margin: 25px 0 10px;
   }

   .site-layout-background {
      margin: 0 25px;
      text-align: left;
   }

   h4 {
      float: left;
      font-weight: normal !important;
      margin-bottom: 30px;
   }

   .anticon-edit {
      float: right;
      svg {
         font-size: 25px;
      }
   }
   .title {
      font-size: 20px;
      color: #262626;
      margin-top: 3px;
      text-align: left;
   }

   .title-search {
      font-size: 20px;
      font-weight: bold;
      text-align: left;
   }

   .title-questions {
      color: #262626;
      text-align: left !important;
      margin-bottom: 10px;
      font-size: 20px;
   }

   .subtitle {
      margin-bottom: 10px;
      color: #262626;
      font-size: 16px;
      z-index: 1;
      text-align-last: left;
   }

   .col {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
   }

   .row {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      margin-bottom: 30px;
   }
   .row-vertical {
      display: flex;
      align-items: flex-start;
      margin: 16px 0;
      flex-direction: column;
   }

   .button-goback {
      margin-right: 16px;
      background-color: #ffffff;
      color: #1db14b;
   }

   .errorText {
      align-self: flex-start;
      color: #e33323;
      display: inline;
      font-size: initial;
   }

   .productView {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      margin-right: 10px;
   }

   .productViewSpace {
      display: flex;
      flex-direction: column;
      padding: 6px;
      border: 1px solid rgba(0, 0, 0, 0.1);
   }

   .select {
      text-align: left;
   }

   .checkbox {
      margin-bottom: 6px;
      font-size: 16px;
      input {
         margin: 10px 7px 0 0;
         width: 16px;
         height: 16px;
      }
   }

   .input-checkbox {
      height: 20px !important;
      font-size: 16px;
      border: none;
      outline: none;
      border-bottom: 1px solid #000;
      width: 62% !important;
   }

   .display-input {
      display: flex;
   }

   .other-option-radio {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
   }

   input[type='number'] {
      border: none;
      border-bottom: 1px solid;
      margin: 0 3px;
      outline: none;
      width: 60px;
   }
   input[type='number']::-webkit-inner-spin-button {
      opacity: 1;
   }
}

.entrepreneurs-modal {
   width: 1024px !important;
   height: 535px;

   .ant-modal-title {
      color: #6e1850;
      font-weight: 600;
      font-size: 20px;
   }

   .ant-modal-body {
      font-size: 20px;
      text-align: center;
   }

   .ant-row {
      margin-top: 5px;
      margin-bottom: 23px;
   }

   span {
      font-size: 16px;
   }
   
   @media screen and (max-width: 720px) {
      .ant-page-header {
         padding: 16px 20px;
      }

      .ant-radio-group {
         display: grid !important;
      }

      .ant-page-header-heading-title {
         margin: 20px 0 10px !important;
         white-space: break-spaces !important;
         font-size: 22px !important;
      }

      .ant-row {
         display: inline-block !important;
         width: 100% !important;
      }

      .ant-col-10,
      .ant-col-12,
      .ant-col-16 {
         max-width: 100% !important;
      }

      .ant-radio-wrapper {
         font-size: 16px !important;
      }

      .site-layout-background {
         margin: 0 24px 0 24px !important;
      }

      .title-questions {
         font-size: 17px !important;
         font-weight: bold;
      }

      .checkbox {
         float: left;
         text-align: left;
      }

      .other-option-radio {
         display: inline-block !important;
      }

      .row-vertical {
         margin: 0 !important;
         margin-bottom: 20px !important;
      }

      .ant-btn-group {
         position: relative;
         display: inline-flex;
         float: left;

         .ant-btn {
            width: 80px;
         }
      }

      .errorText {
         display: inherit !important;
      }

      .entrepreneurs-search-container .subtitle {
         text-align-last: center !important;
         padding: 8px 26px;
      }
      
      .entrepreneurs-modal {
         .ant-modal-body {
            padding: 0px;
         }
      }

      .display-input {
         display: block !important;
      }

      .ant-btn-primary {
         width: auto;
      }
   }

   @media screen and (min-device-width: 721px) and (max-device-width: 1024px) {
      .title-search,
      .title {
         font-size: 14px !important;
         .title {
            font-size: 14px !important;
            margin-top: 0px !important;
         }
      }
   }
}
