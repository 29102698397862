.ant-layout-header {
  background: #fafafa !important;
  height: 80px !important;
  padding: 10px 50px !important;
  display: flex !important;
  justify-content: space-between;

  .userInfo {
    align-self: center;

    h5 {
      margin: 0 !important;
      font-size: 14px !important;
      letter-spacing: 0.56px;
      color: #a5a5a5;
      font-weight: normal;
    }

    h5:first-of-type {
      text-transform: uppercase;
    }

    span {
      display: block;
    }
  }
}
.ant-btn-menu {
  //width: 45px;
  height: 45px;
  background: #98637e !important;
  border-color: #98637e !important;
  border-radius: 0px !important;
  opacity: 1;
  color: white !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}

.ant-btn-menu:hover {
  background-color: #98637e;
}

.baseLayout-layout {
  min-height: 100% !important;

  .logout-button {
    color: #6e1850 !important;
    cursor: pointer;
  }
}

.ant-btn-menu:focus {
  background-color: #98637e;
}
.ant-layout {
  min-height: 100vh;
  background: #fff !important;
  // box-shadow: 2px 10px 11px 0px black;
  // margin-bottom: -20px;
}

img {
  width: 100px;
  height: 60px;
}

.ant-layout-sider {
  background: #98637e !important;
  margin-bottom: -20px;

  ul {
    background: #98637e !important;
  }

  .ant-layout-sider-trigger {
    background: #98637e;
  }
}

.layout-small {
  display: flex;
  flex-direction: row;
}
.menu-small {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.menu-color {
  background-color: #98637e !important;
}

@media screen and (max-width: 640px) {
  .ant-layout {
    display: contents;
  }

  .ant-layout-header {
    padding: 10px 15px !important;
    text-align-last: right;
  }
}
