.inscribe-entity-list-container {
   p {
      text-align: -moz-left;
   }

   .ant-page-header {
      padding-left: 0;
   }

   .ant-page-header-heading-title {
      color: #6e1850 !important;
      font-size: 24px;
      letter-spacing: 0.24px;
      margin: 25px 0 10px;
   }

   .site-layout-background {
      width: 90%;
      margin: 20px auto;
      min-height: 280px;
   }

   .search-input-row {
      margin-top: 19px;
      width: 100%;
   }

   .search-input-name-column {
      width: 47%;
   }

   .search-input-cnpj-column {
      width: 33%;
   }

   .search-input-label {
      font-weight: bold;
      font-size: 16px;
   }

   .form-error-message {
      font-weight: normal;
      color: red;
      font-size: 16px;
   }

   .search-input-button-column {
      width: 20%;
   }

   .search-button {
      width: 100%;
      min-width: 85px;
      height: 41px;
      background-color: #1db14b;
      color: #fafafa;
      border-radius: 6px;
      font-size: 14px;
      margin-top: 41.15px;
   }

   .ant-btn:hover {
      background-color: #fafafa;
      color: #1db14b !important;
      border-color: #1db14b !important;
   }

   .ant-btn:focus {
      background-color: #fafafa;
      color: #1db14b !important;
      border-color: #1db14b !important;
   }

   .ant-table-wrapper {
      margin-top: 15px;
   }

   .ant-table-thead {
      font-size: 20px;

      .ant-table-cell {
         background-color: #f5ebeb;
         color: #98637e;
         padding-left: 42px;
      }
   }

   .ant-table-tbody {
      font-size: 18px;

      .ant-table-cell {
         background-color: #fafafa;
         color: #8e8e8e;
         padding-left: 42px;
      }

      .anticon-plus,
      .anticon-minus {
         color: #98637e;
         font-size: 20px;
      }

      button {
         height: 37px;
         color: #6e1850;
         border-color: #6e1850;
         border: 1px solid;
         border-radius: 6px;
         background-color: #fafafa;
      }
   }

   @media screen and (max-width: 720px) {
      .search-input-name-column,
      .search-input-cnpj-column {
         width: 100%;
      }

      .search-input-button-column {
         width: 40%;
      }
   }
}
