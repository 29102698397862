.entities-search-container {
  .ant-btn-primary {
    width: 158px;
    height: 45px;
    background: #1db14b;
    border-radius: 6px !important;
    border-color: #1db14b !important;
    opacity: 1;
    margin: 0 10px;
    color: white;
    font-size: 16px;
  }

  .ant-btn-primary:hover {
    background-color: #1db14b;
    border-color: #fafafa !important;
    color: #fafafa;
  }

  .ant-btn-primary:focus {
    background-color: #1db14b;
    border-color: #fafafa !important;
    color: #fafafa;
  }
  .ant-checkbox-wrapper {
    margin-left: 8px;
  }
  .ant-page-header {
    padding: 0px 24px;
    margin-top: 20px;
  }
  .default-field {
    height: 45px;
    opacity: 1;
    background: #fafafa;
    border: 1px solid #dbdbdb;
  }
  .otherfield {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .title-extend {
    width: 300px;
  }

  .site-layout {
    background: #ffffff 0% 0% no-repeat padding-box;
  }

  .ant-page-header-heading-title {
    color: #6e1850 !important;
    font-size: 24px;
    letter-spacing: 0.24px;
    margin: 25px 0 10px;
  }

  .site-layout-background {
    margin: 24px 35px;
    min-height: 280px;
    text-align: -webkit-center;
  }

  .form-container-entities {
    justify-content: center;
  }

  .form-error-message {
    font-weight: normal;
    color: red;
    font-size: 16px;
  }

  h4 {
    float: left;
    font-weight: normal !important;
    margin-bottom: 30px;
  }

  .default-table {
    th {
      background: #c4a7b4;
      font-size: 16px;
      line-height: 15px;
    }
    td {
      background: #fafafa;
      font-size: 16px;
      color: #8e8e8e;

      span {
        float: right;
      }
      svg {
        font-size: 20px;

        .a {
          fill: #6e1850;
        }
      }
    }

    .ant-pagination-item-link {
      color: #6e1850;
    }

    .ant-pagination-next {
      :hover {
        border-color: #6e1850 !important;

        svg {
          fill: #6e1850 !important;
        }
      }
    }

    .ant-pagination-item {
      a {
        color: #c4a7b4;
      }
    }

    .ant-pagination-item-active {
      a {
        color: #98637e;
      }
      border-color: #6e1850;
    }

    .ant-table-pagination.ant-pagination {
      margin: 16px 2%;
    }
    .ant-table {
      width: 96%;
    }
  }

  .form-entities {
    width: 90%;
    margin-bottom: 50px;

    .ant-col-6 {
      margin-right: auto;
    }
  }

  .anticon-edit {
    float: right;
    svg {
      font-size: 25px;
    }
  }
  .title {
    font-size: 20px;
    color: #262626;
    margin-top: 3px;
    text-align: left;
  }

  .title-search {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }

  .title-questions {
    color: #262626;
    text-align: left !important;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .subtitle {
    margin: -10px 24px 30px;
    color: #262626;
    font-size: 16px;
    z-index: 1;
  }

  .col {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  .row {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 30px;
  }
  .row-vertical {
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
    flex-direction: column;
  }

  .button-goback {
    margin-right: 16px;
    background-color: #ffffff;
    color: #1db14b;
  }

  .errorText {
    align-self: flex-end;
    color: #e33323;
  }

  .errorTextInfrastructure {
    color: #e33323;
  }

  .checkbox {
    margin-bottom: 6px;
    font-size: 16px;
  }

  .input-checkbox {
    height: 20px;
    font-size: 16px;
    border: 0px;
    outline: 0px;
    border-bottom: 1px solid #000;
  }

  .field-set {
    border: 2px solid #c4a7b4;
    display: 'flex';
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    padding-left: 10px;
    margin: 0 10px 40px 10px;
  }

  .field-set-title-div {
    display: flex;
    text-align: left;
    margin-top: -40px;
  }

  .field-set-title {
    background-color: white;
    padding: 0 12px;
    font-size: 24px;
    color: #262626;
    font-weight: bold;
  }

  .field-set-children {
    padding-left: 10px;
    margin-top: 10px;
  }

  .other-option-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .default-button,
  .button-goback {
    margin: 0 0 20px 20px !important;
  }

  .adession-button {
    margin: 0 0 20px 20px !important;
    width: auto;
    background-color: #4ca3d7;
    border-color: #4ca3d7 !important;
    .ant-btn-primary {
      background: #4ca3d7;
      //border-color: #4CA3D7;
    }

    .ant-btn-primary:hover {
      background: #4ca3d7;
      border-color: #fff !important;
    }
  }

  .line-field-height {
    margin-top: 20px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 720px) {
    .ant-page-header-heading {
      margin: 60px auto 10px;
    }

    .ant-input {
      width: 100% !important;
    }

    .default-button {
      width: 80px;
    }

    #entities-new-entity-button,
    #entities-search-entity-button,
    #entities-add-program-adhesion-button {
      width: auto;
      padding: 0 3%;
    }

    #entities-add-go-back-button {
      width: 80px;
    }
  }
}

@media screen and (max-width: 300px) {
  .default-field-container {
    padding: 0 22px 0 0 !important;
  }
}

@media screen and (min-width: 301px) and (max-width: 680px) {
  .default-field-container {
    padding: 0 !important;
  }
}

@media screen and (max-width: 720px) {
  .ant-page-header {
    padding: 16px 20px;
  }

  .ant-page-header-heading-title {
    margin: 20px 0 10px !important;
    white-space: break-spaces !important;
    font-size: 22px !important;
  }

  .ant-row {
    display: inline-block !important;
    width: 100% !important;
  }

  .site-layout-background {
    margin: 0 24px 0 24px !important;
  }

  .form-entities {
    width: 100% !important;
    margin-bottom: 0 !important;

    .ant-col-6 {
      display: block !important;
      max-width: none !important;
    }
  }

  .table {
    max-width: 100% !important;
  }

  .ant-table {
    display: block;
    overflow-x: scroll;
  }

  .entities-search-container .title-search,
  .title {
    font-size: 18px !important;
    font-weight: normal !important;
  }

  .title-add {
    margin: 10px 0 3px 0 !important;
  }

  .entities-search-container .default-table th {
    line-height: 15px !important;
    padding: 5px 8px;
  }

  .entities-search-container .default-table td {
    line-height: 1.3;
  }

  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 15px !important;
    width: 135px;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  .field-set {
    border: none !important;
    margin: 10px 0 !important;
    padding: 0 0 0 10px !important;
  }

  .field-set-children {
    margin-top: 0 !important;
  }

  .field-set-title {
    font-size: 20px !important;
    margin-top: 25px;
  }

  .field-set-title-div {
    display: inline-block !important;
  }

  .title-questions {
    font-size: 17px !important;
  }

  input[name='entity_name'],
  .ant-picker,
  input[name='cnpj'],
  input[name='corporate_name'],
  input[name='cep'],
  input[name='adress'],
  input[name='number'],
  input[name='complement'],
  input[name='neighborhood'],
  input[name='city'],
  input[name='state'],
  input[name='phone'],
  input[name='mobile_phone1'],
  input[name='mobile_phone2'],
  input[name='email'],
  input[name='site'] {
    width: auto;
    min-width: -webkit-fill-available;
  }

  .checkbox {
    float: left;
    text-align: left;
  }

  .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }

  .other-option-checkbox {
    justify-content: flex-start !important;
  }

  .row-vertical {
    margin-bottom: 20px !important;
  }

  .ant-btn-group {
    position: relative;
    display: inline-flex;
    float: left;

    .ant-btn-primary:first-child:not(:last-child) {
      padding: 0 6px;
    }
  }
}

@media screen and (min-device-width: 721px) and (max-device-width: 1024px) {
  .entities-search-container .site-layout-background {
    margin: 0 !important;
  }
  .entities-search-container .form-entities {
    margin-bottom: 30px;
  }

  .title-search,
  .title {
    font-size: 14px !important;
    .title {
      font-size: 14px !important;
      margin-top: 0px !important;
    }
  }

  .table {
    max-width: 90% !important;
  }

  .entities-search-container .default-table th,
  .entities-search-container .default-table td {
    line-height: 15px !important;
    padding: 5px 8px;
  }

  input[name='entity_name'],
  .ant-picker,
  input[name='cnpj'],
  input[name='corporate_name'],
  input[name='cep'],
  input[name='adress'],
  input[name='number'],
  input[name='complement'],
  input[name='neighborhood'],
  input[name='city'],
  input[name='state'],
  input[name='phone'],
  input[name='mobile_phone1'],
  input[name='mobile_phone2'],
  input[name='email'],
  input[name='site'] {
    width: auto !important;
  }
}
.ant-input {
  height: 40px !important;
}
