.default-menu{
   background: #98637E!important;
   font-size: 18px!important;
   letter-spacing: 0.4px!important;
   color: white!important;
   opacity: 1;

   :hover{
      color: white!important;
   }
   ::after{
      border: none!important;
   }
}

.notPermission {
   display: none !important;
}

.ant-menu{
   .ant-menu-submenu-active{
      :hover{
         color: #BEDC8E!important;
         .a{
            fill: #BEDC8E!important;
         }
      }
   }

   .ant-menu-submenu-selected{
      svg{
         .a{
            fill: #BEDC8E!important;
         }
      }
   }

   .ant-menu-submenu-open{
      color: #BEDC8E!important;

      .a{
         fill: #BEDC8E!important;
      }

      .ant-menu-item-active{
         :hover{
            color: #BEDC8E!important;
         }
      }
   }
}

.ant-menu-item-selected{
   background: #98637E!important;
}

.menu-item-margin {
   margin: 30px auto !important;
}

.menu-item-margin a {
   color: white !important;
}

.ant-menu-item{
   font-size: 16px;

   svg{
      font-size: 18px;
   }
}

.ant-menu-submenu{
   background: #98637E!important;
   font-size: 16px;
   margin: 30px 0;
   color: #fff;

   .ant-menu-item{
      font-size: 14px;
   }

   svg{
      font-size: 20px;

      .a{
         fill: white!important;
      }
   }
}
@media screen and (max-width: 640px) {
   .ant-menu-submenu{
      margin: 10px 0;
   }
}


.ant-menu-sub{
   background: #98637E!important;

   .ant-menu-item-selected{
      background: #98637E!important;
   }
}

.ant-menu-submenu-title{
   height: auto;

   span{
      line-height: normal;
      white-space: break-spaces;
   }
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
   white-space: break-spaces;
   line-height: normal;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span{
   display: block!important;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon, .ant-menu-dark .ant-menu-item-selected .anticon{
   color: #BEDC8E!important;
}

.ant-menu-dark .ant-menu-item-selected > span > a{
   color: #BEDC8E!important;
}

.ant-menu-dark .ant-menu-item:hover > span > a, .ant-menu-dark .ant-menu-item-active > span > a{
   color: #BEDC8E!important;

   :hover{
      .anticon-home{
         color: #BEDC8E!important;
      }
   }
}
