.selected-box{
   display: flex;
   flex: 1;
   text-align: left;
   flex-direction: row;
   padding: 6px;
   margin-top: 20px;
   margin-right: 10px;
   font-size: 20px;
   border: 1px solid rgba(0, 0, 0, 0.1);

   color: #98637E;

   >div{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 6px;
      margin-right: 10px;
   }

   .close-button{
      display: "flex";
      align-items: flex-start;
      color: #98637E;

      svg{
         font-size: 14px;
      }
   }
   .selected-box-itens{
      color: #8E8E8E;
      display: flex;
      flex: 1;
      flex-direction: row;
      font-size: 16;
      text-align: left;
   }
}
